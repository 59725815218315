import "../../styles/loading.css";
import lightLoago from "../../Images/logoGray.png";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Loading = () => {
  const {them} = useSelector(
    state => state.theam
  )
  const [themMode , setThemMode] = useState("dark")
  useEffect(() => {
    const themFromStorage = window.localStorage.getItem("them");
    themFromStorage && themFromStorage === "dark" ? setThemMode("dark") : setThemMode("light")
    console.log(themFromStorage)
  },[them])
  return (
    // them
    // &&
    themMode
    &&
    <section className="load" style={{backgroundClip: themMode === "dark" ? "#000000" : "#fff"}}>
      <div className="container">
        {/* <img src={lightLoago} alt="Loading Logo" /> */}
        <div className="loader">
          <div className="outer"></div>
          <div className="middle"></div>
          <div className="inner"></div>
        </div>
      </div>
    </section>
  );
};
export default Loading;
